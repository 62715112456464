@use '../_variables.scss';
@use '@material/theme' with (
  $primary: variables.$primary-color
);

@use 'raincoat/css/_raincoat-base.scss';
$danger: red;

@use '@material/banner';
@use '@material/banner/styles';
@use '@material/button/mdc-button';
@use '@material/button';
@use '@material/card';
@use '@material/checkbox/mdc-checkbox';
@use '@material/data-table/data-table';
@use '@material/dialog';
@use '@material/fab';
@use '@material/form-field/_index.scss' as form-field;
@use '@material/form-field/mdc-form-field';
@use '@material/icon-button';
@use '@material/icon-button/styles' as icon-button-styles;
@use '@material/image-list';
@use '@material/image-list/mdc-image-list';
@use '@material/linear-progress';
@use '@material/list/evolution-mixins' as list-evolution-mixins;
@use '@material/list/mdc-list';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';
@use '@material/radio';
@use '@material/radio/styles' as radio-styles;
@use '@material/radio/radio-theme' as radio-theme;
@use '@material/slider/styles' as slider-styles;
@use '@material/select/mdc-select';
@use '@material/select/styles' as selectStyles;
@use '@material/tab-bar/mdc-tab-bar';
@use '@material/tab-scroller/mdc-tab-scroller';
@use '@material/tab-indicator/mdc-tab-indicator';
@use '@material/tab/mdc-tab';
@use '@material/textfield/icon';
@use '@material/textfield/mdc-text-field';
@use '@material/tooltip/styles' as tooltip-styles;
@use '@material/top-app-bar/mdc-top-app-bar';
@use '@material/typography';
@use '@material/typography/mdc-typography';

@include card.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
@include dialog.core-styles;
@include fab.core-styles;
@include form-field.core-styles;
@include linear-progress.core-styles;
@include list-evolution-mixins.core-styles;

#body-content {
  min-height: 85vh;

  h1 {
    margin-top: 32px;
    margin-bottom: 24px;
    font-size: 1.75rem;
    font-weight: bold;
    line-height: normal;
  }
}

.admin-page {
  padding-left: variables.$desktop-body-padding;
  padding-right: variables.$desktop-body-padding;

  h1 {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: normal;
  }

  @media (max-width: variables.$mobile-width-max) {
    padding-left: variables.$mobile-body-padding;
    padding-right: variables.$mobile-body-padding;
  }
}

.mdc-delete-btn {
  @include button.filled-accessible($danger);
}

.mdc-top-app-bar__title {
  color: white;
}

.navbar-logo {
  height: 70px;
  padding-top: 10px;
}

.rating {
  .mdc-form-field {
    display: inline-block;

    .mdc-radio {
      display: none;
    }
  }
}

.mdc-data-table__header-cell {
  font-weight: bold;
}

.mdc-card {
  @include card.fill-color(rgba(theme.$primary, 0.3));
  @include card.outline(theme.$primary, 5px);

  margin-top: 16px;

  .mdc-card__media-content {
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 1.5rem;
    padding-top: 16px;
  }
}

.offscreen {
  position: absolute;
  left: -5000px;
}

#footer {
  background-color: theme.$primary;
  color: white;
  padding: 0 36px;
  padding-top: 24px;
  margin-top: 1rem;

  p {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  #footer-top-link {
    text-align: center;
    .mdc-button {
      @include button.filled-accessible(#934ff9);
    }
  }
}

@media (max-width: variables.$mobile-width-max) {
  #footer {
    padding: 0 16px;
    padding-top: 24px;

    #footer-top-link {
      width: 100%;

      .mdc-button {
        width: 100%;
      }
    }
  }
}

form .mdc-text-field {
  margin-top: 16px;
}

@media (max-width: variables.$mobile-width-max) {
  #body-content {
    h1 {
      margin-top: 16px;
      margin-bottom: 8px;
    }
  }
}

.danger {
  color: $danger;
}

.danger-outline {
  border: 3px solid $danger !important;
}
